import { graphql } from 'gatsby';
import React, { useContext, useEffect } from 'react';
import styled from '@emotion/styled';
import ProductGrid from '~/components/ProductGrid';
import {
    breakpoints,
    PageHeader, PageHeadline, SingleSection,
} from '~/utils/styles';
import SEO from '~/components/Seo';
import { getCollectionThemeByCollectionId } from '~/utils';
import { ThemeContext } from '~/context/ThemeContext';
import themes from '~/utils/themes';
import Footer from '~/components/Footer';

const CollectionHeadline = styled(PageHeadline)(() => `
    font-size: 1.25rem;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    letter-spacing: 0.02em;

    @media ${breakpoints.lg} {
        font-size: 2rem;
    }
`);

const ProductCollectionPage = function ProductCollectionPage({
    data: {
        collection,
        // site,
    },
}: any) {
    const { setTheme } = useContext(ThemeContext);
    const { title, shopifyId } = collection;
    const theme = getCollectionThemeByCollectionId(shopifyId) || themes.default;

    useEffect(() => {
        if (theme) {
            setTheme(theme);
        }
    }, [setTheme, theme]);

    return (
        <>
            <SingleSection>
                <SEO title={title} description="" />

                <PageHeader>
                    <CollectionHeadline>
                        {title}
                    </CollectionHeadline>
                </PageHeader>

                <ProductGrid
                    collectionsData={collection}
                />
            </SingleSection>

            <Footer />
        </>
    );
};

export const query = graphql`
    query($handle: String!) {
        collection: shopifyCollection(handle: { eq: $handle }) {
            ...CollectionFields
        }
        site {
            siteMetadata {
                title
            }
        }
    }
`;

export default ProductCollectionPage;
